<template>
    <div class="Logistics">
        <HeaderNav :navIndex="0" />
        <div class="content_center">
            <div class="Logistics-list">
                <TimeLine
                />
            </div>
            <div class="Logistics-map">
                <!-- <Map /> -->
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';

// import Map from './Map.vue';
import TimeLine from './TimeLine.vue';

export default {
    name: 'Logistics',
    components: {
        HeaderNav,
        TimeLine,
        // Map,
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.Logistics{
    min-height: calc(100vh - 170px);
    padding-top: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .HeaderNav{
        background-color: #007ca6;
    }
    .content_center{
        display: flex;
        justify-content: space-between;
        padding: 50px 0;
        .Logistics-list{
            width: 800px;
        }
        .Logistics-map{
            flex: 1;
        }
    }
}
</style>